.logs-table {
  /* Ideally we want all corners to be rounded but the row hover effect is causing issues on the last row */
  border-radius: var(--mantine-spacing-lg);
  border-collapse: separate;

  @mixin dark {
    border-top: solid var(--mantine-color-dark-5) 1px;
  }

  @mixin light {
    border-top: solid var(--mantine-color-gray-3) 1px;
  }
}

.logs-th {
  background: var(--mantine-color-background-0);

  &:first-child {
    border-radius: var(--mantine-spacing-lg) 0 0 0;
  }

  &:last-child {
    border-radius: 0 var(--mantine-spacing-lg) 0 0;
  }
}

.logs-td {
  @mixin dark {
    border-top: solid var(--mantine-color-dark-5) 1px;
  }

  @mixin light {
    border-top: solid var(--mantine-color-gray-3) 1px;
  }
}

.logs-data-box {
  @mixin dark {
    background: var(--mantine-color-dark-4);
  }

  @mixin light {
    background: var(--mantine-color-gray-2);
  }
}

.logs-tr {
  &:hover td {
    background: var(--table-hover-color);
  }

  &:hover:last-child td:first-child {
    border-radius: 0 0 0 var(--mantine-spacing-lg);
  }

  &:hover:last-child td:last-child {
    border-radius: 0 0 var(--mantine-spacing-lg) 0;
  }
}

.logs-tr-expanded:last-child td {
  border-radius: 0 0 var(--mantine-spacing-lg) var(--mantine-spacing-lg);

  /* Disable hover effect on expanded rows, it messes with the rounded corners on the last row */
  pointer-events: none;
}

.request-response-info {
  @mixin dark {
    border-bottom: solid var(--mantine-color-dark-5) 1px;
  }

  @mixin light {
    border-bottom: solid var(--mantine-color-gray-3) 1px;
  }
}
