.input {
  border-radius: var(--mantine-radius-default) var(--mantine-radius-default) 0%
    0%;
}

.row {
  background-color: var(--mantine-color-green-light);

  &:hover {
    background-color: var(--mantine-color-green-light-hover);
    cursor: pointer;
  }
}

.dropdown {
  border-radius: 0% 0% var(--mantine-radius-default)
    var(--mantine-radius-default);
}

.badge_mainnet {
  border: calc(0.0625rem * var(--mantine-scale)) solid green;
}

.badge_testnet {
  border: calc(0.0625rem * var(--mantine-scale)) solid
    var(--mantine-color-yellow-3);
}
