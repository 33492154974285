.header {
  padding: var(--mantine-spacing-md);
}

/* NavbarButton.module.css */
.button {
  width: 100%;
  border-radius: 4px;
  border: none;
  transition: background-color 0.2s;
  font-size: 0.9em;
  font-weight: 400;
  color: var(--mantine-color-primary-text);
}

.non-active {
  background-color: transparent;
  box-shadow: none;
  color: var(--mantine-color-black);

  &:hover {
    color: var(--mantine-color-black);
    background-color: var(--mantine-color-gray-2);
  }
}

.active {
  box-shadow: none;
  color: var(--mantine-color-teal-9);
  background-color: var(--mantine-color-green-light);

  &:hover {
    background-color: var(--mantine-color-green-light);
  }

  svg {
    color: var(--mantine-color-green-6);
  }
}

html[data-mantine-color-scheme="dark"] .active {
  color: var(--mantine-color-green-6);
  background-color: alpha(var(--mantine-color-green-6), 0.15);

  svg {
    color: var(--mantine-color-green-6);
  }
}

html[data-mantine-color-scheme="dark"] .non-active {
  box-shadow: none;
  color: var(--mantine-color-dark-0);

  &:hover {
    color: var(--mantine-color-dark-0);
    background-color: var(--mantine-color-dark-5);
  }

  &:hover svg {
    color: var(--mantine-color-dark-0);
  }
}
