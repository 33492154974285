:root {
  background: var(--mantine-color-background-filled);

  /* stylelint-disable-next-line */
  @mixin dark-root {
    background: var(--mantine-color-background-filled);
  }
}

.mantine-Button-root {
  &[data-disabled] {
    /* You can use Mantine PostCSS mixins inside data attributes */
    @mixin light {
      background: var(--mantine-color-gray-3);
    }

    @mixin dark {
      background: var(--mantine-color-dark-4);
    }
  }
}

svg {
  color: black;

  /* stylelint-disable-next-line */
  @mixin dark {
    color: white;
  }
}

body {
  height: 100vh;
  width: 100vw;
}
